import React from 'react'
import PropTypes from "prop-types"


const PriceList = ({data}) => (
  <div className="columns is-multiline" style={{border: "1px solid #82B4B9", borderRadius: "5px"}}>
    {data.map(item => (
     <div classname="column" style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "0.2rem"}}>
        <div key={item.name} className="column" style={{marginBottom: "auto"}}>
          <section className="section" style={{display: "flex"}}>
            <h4 className="is-size-5 has-text-centered has-text-weight-semibold">
              {item.item}
            </h4>
            <h4 className="is-size-5 has-text-primary" style={{ paddingLeft: "1rem"}}>
          <span>&#163;</span>{item.price}
        </h4>
          </section>
        </div>
     </div>
    ))}
  </div>
)

PriceList.propTypes = {
  data: PropTypes.arrayOf({
    name: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }
  )
}

export default PriceList